import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    *{
        margin: 0;
        padding: 0;
        font-weight: 400;
        border: none;
        outline: none;
        background-color: transparent;
        list-style: none;
        font-family: 'Montserrat', sans-serif;
    }

    html {
        box-sizing: border-box;
    }
    
    *, *:before, *:after {
        box-sizing: inherit;
    }

    button{
        cursor: pointer;
        :disabled{
            cursor: default;
        }
    }

    h1,h2,h3,h4,h5,h6{
        font-size: 1rem;
    }

    a{
        text-decoration: none;
        color: inherit;
        :visited{
            color: inherit;
            text-decoration: none;
        }
    }

    input[readonly]{
        cursor: default;
    }

    textarea {
        resize: none;
    }

    //RESPONSIVE
    html{
        font-size: 16px;

        ${props => props.theme.breakpoints.xl3} {
            font-size: 14px;
        }
        ${props => props.theme.breakpoints.xl} {
            font-size: 12px;
        }
    }
`