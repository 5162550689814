import React from 'react'
import * as S from './GlobalLayout.styles'

interface GlobalLayoutProps {
    children: React.ReactNode
}

export const GlobalLayout = ({ children }: GlobalLayoutProps) => {
    return (
        <S.GlobalLayoutContainer>
            {children}
        </S.GlobalLayoutContainer>
    )
}
