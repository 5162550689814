import { ErrorMessage } from '@hookform/error-message';
import React from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { IconType } from 'react-icons';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { FaTimes } from 'react-icons/fa'
import { useToggle } from '../../hooks/useToggle';
import { If } from '../if/If';
import * as S from './MyInput.styles';

export interface MyInputProps<T> {
    name: T;
    label?: string;
    className?: string;
    placeholder?: string;
    textarea?: boolean;
    readOnly?: boolean;
    type?: string;
    onClickContainer?: () => void;
    IconLeft?: IconType | (() => JSX.Element);
    IconRight?: IconType | (() => JSX.Element);
    onChange?: (element: React.ChangeEvent<HTMLInputElement>) => void;
    setValueAs?: (value: any) => void;
    [key: string]: any;
}

export const MyInput = <T extends string>({
    name,
    label,
    className,
    placeholder,
    textarea,
    type,
    readOnly,
    IconLeft,
    IconRight,
    onChange,
    onClickContainer,
    setValueAs = (value: any) => typeof value === 'string' ? value.trim() : value,
    ...props
}: MyInputProps<T>) => {

    const { register, getValues, setValue } = useFormContext()
    const { errors } = useFormState({ name })
    const [isFocused, setIsFocused] = React.useState(false)
    const [showPassword, toggleShowPassword] = useToggle()

    const element = textarea ? 'textarea' : 'input'
    const isPassword = type === 'password'
    const hasErrors = !!errors[name]
    const showIconRight = !!IconRight && !isPassword && !hasErrors
    const realSetValueAs = isPassword ? undefined : setValueAs
    const EyeIcon = showPassword ? AiOutlineEyeInvisible : AiOutlineEye
    const inputType = (isPassword && !showPassword)
        ? type
        : 'text'
    const realInputType = type === "date" ? "date" : inputType

    const onFocus = () => setIsFocused(true)

    const onBlur = () => {
        const value = getValues(name)
        if (value) {
            setIsFocused(true)
        } else {
            setIsFocused(false)
            setValue(name, "" as any)
        }
    }

    const handleClickContainer = () => {
        const input = document.getElementById(name)
        input?.focus()
        setIsFocused(true)
        onClickContainer?.()
    }

    return (
        <S.InputWrapper className={`input-wrapper ${className}`}>
            {label && <label htmlFor={name} className="input-label">{label}</label>}
            <S.InputContainer
                className='input-container'
                iconLeft={!!IconLeft}
                iconRight={!!IconRight}
                isFocused={isFocused}
                onClick={handleClickContainer}
                isValid={!hasErrors}
                readOnly={readOnly}
            >
                {IconLeft && <IconLeft className='input-icon-left' />}
                <S.Input
                    as={element}
                    id={name}
                    autoComplete="off"
                    placeholder={placeholder}
                    type={realInputType}
                    className="input-element"
                    onFocus={onFocus}
                    readOnly={readOnly}
                    {...register(name, {
                        onBlur,
                        onChange,
                        setValueAs: realSetValueAs,
                    })}
                    {...props}
                />

                {showIconRight && <IconRight className='input-icon-right' />}

                <If showIf={!isPassword && hasErrors && type !== "date"}>
                    <FaTimes className='input-error-icon' />
                </If>

                <If showIf={isPassword}>
                    <EyeIcon className='input-icon-right password-icon' onClick={toggleShowPassword} />
                </If>
            </S.InputContainer>
            <ErrorMessage
                name={name}
                render={({ message }) => <span className='input-error'>{message}</span>}
            />
        </S.InputWrapper>
    )
}