import React from 'react'
import { IconType } from 'react-icons';
import { Box } from '../box/Box';
import { Loader } from '../loader/Loader';
import { If } from '../if/If';
import { StandardButtonProps, StandardButtonStyles } from './button.styles';

interface Props extends StandardButtonProps {
    Icon?: IconType
    onClick?: () => void

    text?: string
    tippy?: string
    style?: React.CSSProperties
    iconPosition?: 'left' | 'right'
    children?: React.ReactNode
    disabled?: boolean
    loading?: boolean
    loaderSize?: string
    loaderColor?: string
    animate?: boolean
    className?: string
    [key: string]: any
}

export const Button = (props: Props) => {
    const {
        Icon,
        children,
        disabled,
        loading,
        animate,
        loaderColor,
        loaderSize,
        text,
        tippy,
        ...rest
    } = props;

    const isDisabled = disabled || loading;
    const flexDirection = props.iconPosition === 'left' ? 'row' : 'row-reverse';

    const buttonContent = (
        <StandardButtonStyles
            disabled={isDisabled}
            {...rest}
        >
            <If showIf={loading}>
                <Loader color={loaderColor} size={loaderSize || props.fontSize} />
            </If>
            <If showIf={!loading}>
                <Box
                    flex alignCenter justifyCenter gap=".5rem"
                    flexDirection={flexDirection}
                >
                    {Icon && <Icon />}
                    {children || text}
                </Box>
            </If>
        </StandardButtonStyles>
    )

    return buttonContent;
}