import styled from "styled-components";
import { publicBg } from "../assets";

export const GlobalLayoutContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    min-height: 100vh;
    background-color: #f5f5f5;

    background-image: url(${publicBg});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
`;